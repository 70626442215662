<template>
  <div id="app">
    <el-container>
      <el-header>
        <h1>硬件钱包签名工具</h1>
      </el-header>
      <el-main>
        <QrScanner />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import QrScanner from './components/QrScanner.vue';

export default {
  name: 'App',
  components: {
    QrScanner
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
