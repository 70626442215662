<template>
  <div>


    <div>
      <div>地址列表</div>
      <el-button @click="startHdScan">开始扫码</el-button>
      <el-button @click="stopHdScan">停止扫码</el-button>
      <div id="hdReader" style="width: 500px; margin-top: 20px;"></div>
      <el-table
          :data="hdAddressList"
          style="width: 100%" height="250">
        <el-table-column prop="address" label="地址"></el-table-column>
        <el-table-column prop="path" label="路径"></el-table-column>
        <el-table-column prop="xfp" label="xfp"></el-table-column>
        <el-table-column prop="time" label="扫描时间"></el-table-column>
        <el-table-column prop="operation" label="操作">
          <template  #default="scope">
            <el-button @click="delAddress(scope.row)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div>
      <div>签名记录</div>
      <el-button @click="startSignForm">开始签名</el-button>
      <el-table
          :data="signList"
          style="width: 100%" height="250">
        <el-table-column prop="from" label="地址"></el-table-column>
        <el-table-column prop="nonce" label="nonce"></el-table-column>
        <el-table-column prop="to" label="to地址"></el-table-column>
        <el-table-column prop="hex" label="16进制数据"></el-table-column>
        <el-table-column prop="rawTx" label="签名"></el-table-column>
        <el-table-column prop="time" label="签名时间"></el-table-column>
        <el-table-column prop="operation" label="操作">
          <template  #default="scope">
            <el-button @click="copySign(scope.row)" type="info" size="small">复制参数签名</el-button>
            <el-button @click="delSign(scope.row)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog v-model="dialogSignFormVisible" title="签名参数" width="600"  :before-close="handleClose">
      <el-form :model="signForm">
        <el-form-item label="链id" :label-width='140'>
          <el-input v-model="signForm.chainId" autocomplete="off" />
        </el-form-item>
        <el-form-item label="from" :label-width="140">
          <el-select v-model="signForm.from" placeholder="请选择一个地址发出tx">
            <el-option
                v-for="item in hdAddressList"
                :key="item.address"
                :label="item.label"
                :value="item.address"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="to(合约地址)" :label-width='140'>
          <el-input v-model="signForm.to" autocomplete="off" />
        </el-form-item>
        <el-form-item label="nonce" :label-width='140'>
          <el-input v-model="signForm.nonce" autocomplete="off" />
        </el-form-item>
        <el-form-item label="value" :label-width='140'>
          <el-input v-model="signForm.value" autocomplete="off" />
        </el-form-item>
        <el-form-item label="16进制数据" :label-width='140'>
          <el-input v-model="signForm.hex" autocomplete="off" />
        </el-form-item>
        <el-form-item label="gasLimit" :label-width='140'>
          <el-input v-model="signForm.gasLimit" autocomplete="off" />
        </el-form-item>
        <el-form-item label="最大gas(gwei)" :label-width='140'>
          <el-input v-model="signForm.maxFeePerGas" autocomplete="off" />
        </el-form-item>
        <el-form-item label="gas小费(gwei)" :label-width='140'>
          <el-input v-model="signForm.maxPriorityFeePerGas" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div style="padding: 20px">
        <el-button @click="generateQrCode" style="margin-left: 10px;">生成二维码</el-button>
        <canvas id="qr-code" style="margin-top: 20px;"></canvas>
      </div>
      <div>
        <el-button @click="startSignScan">开始扫码</el-button>
        <el-button @click="stopSignScan">停止扫码</el-button>
        <div id="signReader" style="width: 500px; margin-top: 20px;"></div>
      </div>
      <div>
        <div>签名结果</div>
        <div id="signRawTx">{{signForm.rawTx}}</div>
      </div>


    </el-dialog>
  </div>
</template>

<script>
import { Html5QrcodeScanner } from 'html5-qrcode';
import QRCode from 'qrcode';
import { CryptoHDKey, RegistryTypes,URDecoder, EthSignRequest,
  DataType,
  ETHSignature, } from '@onekeyfe/hd-air-gap-sdk';
import HDKey from "hdkey";

import { FeeMarketEIP1559Transaction }  from'@ethereumjs/tx';
import { v4 as uuidv4 , stringify as uuidStringify} from 'uuid';
import { ethers } from 'ethers';
import {
  toChecksumAddress,
  publicToAddress,
  stripHexPrefix,
} from "@ethereumjs/util";

export default {
  name: 'QrScanner',
  data() {
    return {
      hdAddressList: JSON.parse(localStorage.getItem('hdAddressList')) || [],
      signList: JSON.parse(localStorage.getItem('signList')) || [],
      hdScanner: null,
      signScanner: null,
      signForm: {
      },
      dialogSignFormVisible: false,
      scanHdLoading: false,
      scanSignLoading: false,
    };
  },
  methods: {
    startSignForm() {
      this.dialogSignFormVisible = true
    },
    startHdScan() {
      if (this.hdScanner) {
        this.hdScanner.clear();
      }
      this.hdScanner = new Html5QrcodeScanner("hdReader", { fps: 10, qrbox: 250 });
      this.hdScanner.render(this.onHdScanSuccess, this.onHdScanFailure);
    },
    stopHdScan() {
      if (this.hdScanner) {
        this.hdScanner.clear();
      }
    },
    startSignScan() {
      if (this.signScanner) {
        this.signScanner.clear();
      }
      this.signScanner = new Html5QrcodeScanner("signReader", { fps: 10, qrbox: 250 });
      this.signScanner.render(this.onSignScanSuccess, this.onSignScanFailure);
    },
    stopSignScan() {
      if (this.signScanner) {
        this.signScanner.clear();
      }
    },
    showMessage(action) {
      this.$message({
        type: 'error',
        message: `action: ${ action }`
      });
    },
    onSignScanSuccess(decodedText, decodedResult) {
      if (this.scanSignLoading) {
        return;
      }
      this.scanSignLoading = true
      console.log(decodedText)
      const decoder = new URDecoder();
      let UR = decodedText
      decoder.receivePart(UR);

      const ur = decoder.resultUR();

      const ethSignature = ETHSignature.fromCBOR(ur.cbor);

      const signature = ethSignature.getSignature();
      let requestIdOrg = ethSignature.getRequestId();
      let requestId = uuidStringify(requestIdOrg)
      console.log(requestId)
      if (this.signForm.requestId !== requestId) {
        this.showMessage('该二维码非以上参数的签名')
        this.scanSignLoading = false
        return
      }
      const r = signature.slice(0, 32);
      const s = signature.slice(32, 64);
      const v = signature.slice(64, 65);


      const _txParams = {
        "from": this.signForm.from,
        "gasLimit": this.signForm.gasLimit*1,
        "to": this.signForm.to,
        "value": (this.signForm.value && this.signForm.value*1 >0)? ethers.parseEther(this.signForm.value) :0,
        "data": this.signForm.hex??null,
        "maxFeePerGas": ethers.parseUnits(this.signForm.maxFeePerGas,'gwei'),
        "maxPriorityFeePerGas": ethers.parseUnits(this.signForm.maxPriorityFeePerGas,'gwei'),
        "type": 2,
        "nonce":this.signForm.nonce*1,
        chainId: this.signForm.chainId*1,
        signature: {
          r: "0x" + r.toString('hex'),
          s: "0x" + s.toString('hex'),
          v: "0x" + v.toString('hex'),
        }
      }
      console.log(_txParams)
      console.log('等待serializeTransaction')


      let txx = ethers.Transaction.from(_txParams)
      let rawTx = txx.serialized
      //
      console.log(`RAW TX:`, rawTx)
      console.log(txx.from)

      console.log(txx.to)

      this.signForm.rawTx = rawTx

      if (txx.from.toLowerCase() !== this.signForm.from.toLowerCase()) {
        this.scanSignLoading = false
        this.showMessage(`签名数据有误`)
        return
      }
      this.signList.push({
        ...this.signForm,
        time: new Date().toLocaleString()
      });
      localStorage.setItem('signList', JSON.stringify(this.signList));
      if (this.signScanner) {
        this.signScanner.clear();
      }
      this.scanSignLoading = false
    },
    onSignScanFailure(error) {
      // console.warn(`Scan error: ${error}`);
    },
    generateQrCode() {
      console.log(this.signForm)
      if (!this.signForm.from) {
        this.showMessage('请选择发起tx的地址')
        return
      }

      let _txParams = {
        // "from": this.signForm.from,
        "gasLimit": this.signForm.gasLimit*1,
        "to": this.signForm.to,
        "value": (this.signForm.value && this.signForm.value*1 >0)? ethers.parseEther(this.signForm.value) :0,
        "data": this.signForm.hex??null,
        "maxFeePerGas": ethers.parseUnits(this.signForm.maxFeePerGas,'gwei'),
        "maxPriorityFeePerGas": ethers.parseUnits(this.signForm.maxPriorityFeePerGas,'gwei'),
        "type": 2,
        "nonce":this.signForm.nonce*1,
        chainId: this.signForm.chainId*1,
      }
      console.log(_txParams)
      const hdAddress = this.hdAddressList.find(item => item.address.toLowerCase() === this.signForm.from.toLowerCase())
      console.log(hdAddress)

      const eip1559Tx = FeeMarketEIP1559Transaction.fromTxData(_txParams);

      const unsignedBuffer = Buffer.from(eip1559Tx.getMessageToSign()); // generate the unsigned transaction bytes
      const requestId = uuidv4();
      this.signForm.requestId = requestId
      const ethSignRequest = EthSignRequest.constructETHRequest(
          unsignedBuffer,
          DataType.typedTransaction,
          hdAddress.path,
          hdAddress.xfp, // master fingerprint
          requestId,
          _txParams.chainId, // chainId
          _txParams.from,
      );
// console.log(ethSignRequest.toCBOR().toString('hex'))

// each chunk Number in single QR Code
      const maxChunkNumber = 200;
// get the ur encoder
      const urEncoder = ethSignRequest.toUREncoder(maxChunkNumber);

      let urLink = urEncoder.nextPart()
      console.log(urLink)

      QRCode.toCanvas(document.getElementById('qr-code'), urLink, { width: 200 }, (error) => {
        if (error) console.error(error);
        console.log('QR code generated!');
      });
    },
    onHdScanSuccess(decodedText, decodedResult) {
      if (this.scanHdLoading) {
        return;
      }
      this.scanHdLoading = true
      console.log(decodedText)
      if (decodedText.toLowerCase().indexOf('ur') !== 0) {
        this.showMessage(`不是想要的硬件钱包`)
        return
      }
      const DEFAULT_CHILDREN_PATH = "0/*";
      const decoder = new URDecoder();
      decoder.receivePart(decodedText);
      const result = decoder.resultUR();

      if (result.type !== RegistryTypes.CRYPTO_HDKEY.getType()) {
        // error qr code
        console.log(result.type)
        this.showMessage(`${result.type} 不是想要的硬件钱包`)
        return
      }

      const cryptoHDKey = CryptoHDKey.fromCBOR(result.cbor);

//
      const xpub = cryptoHDKey.getBip32Key();
      const childrenPath = cryptoHDKey.getChildren()?.getPath() ?? DEFAULT_CHILDREN_PATH;
      const hdPath = `m/${cryptoHDKey.getOrigin().getPath()}`;
// This parameter is required for subsequent eth sign request assembly.
      const xfp = cryptoHDKey.getOrigin().getSourceFingerprint()?.toString("hex");
//
// derive child
      for (let i=0;i<5;i++) {
        const accountIndex = i
        const derivePath = childrenPath
            .replace("*", String(accountIndex))
            .replace(/\*/g, "0");

        const hdk = HDKey.fromExtendedKey(xpub);
        const dkey = hdk.derive(`m/${derivePath}`);

        console.log("path:", hdPath +'/' +derivePath)
        console.log("xfp:", xfp)
        const address =
            "0x" + Buffer.from(publicToAddress(dkey.publicKey, true)).toString('hex');
        const addressWithChecksum = toChecksumAddress(address);

        console.log("address:", addressWithChecksum);
        let filter = this.hdAddressList.find(item => {return addressWithChecksum.toLowerCase() === item.address.toLowerCase()})
        if (!filter) {
          this.hdAddressList.push({
            address: addressWithChecksum,
            path: hdPath +'/' +derivePath,
            xfp: xfp,
            time: new Date().toLocaleString()
          });
        }

      }
      localStorage.setItem('hdAddressList', JSON.stringify(this.hdAddressList));



      if (this.hdScanner) {
        this.hdScanner.clear();
      }
      this.scanHdLoading = false
    },
    onHdScanFailure(error) {
      // console.warn(`Scan error: ${error}`);
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    delAddress(row) {
      console.log(row)
      this.$confirm(`确定要删除该钱包${row.address}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        for (let i=0;i<this.hdAddressList.length;i++) {
          let addressItem = this.hdAddressList[i]
          if (row.address.toLowerCase() === addressItem.address.toLowerCase()) {
            this.hdAddressList.splice(i,1)
            localStorage.setItem('hdAddressList', JSON.stringify(this.hdAddressList));
            break
          }
        }
      }).catch(() => {

      })
    },
    delSign(row) {
      console.log(row)
      this.$confirm(`确定要删除该签名吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        for (let i=0;i<this.signList.length;i++) {
          let signItem = this.signList[i]
          if (row.requestId.toLowerCase() === signItem.requestId.toLowerCase()) {
            this.signList.splice(i,1)
            localStorage.setItem('signList', JSON.stringify(this.signList));
            break
          }
        }
      }).catch(() => {

      })
    },
    copySign(row) {
      console.log(row)
      this.signForm = JSON.parse(JSON.stringify(row))
      this.signForm.requestId = null
      this.signForm.rawTx = null
      try {
        if (document.getElementById('qr-code')) {
          var cxt=document.getElementById("qr-code").getContext("2d");
          cxt.clearRect(0,0,cxt.width,cxt.height);
        }
      } catch (e) {
        console.log(e)
      }

      this.dialogSignFormVisible = true

    },
  },
  beforeUnmount() {
    if (this.hdScanner) {
      this.hdScanner.clear();
    }
    if (this.signScanner) {
      this.signScanner.clear();
    }
  }
};
</script>

<style scoped>
#reader {
  margin: auto;
}
</style>
